import React from 'react'
import Layout from "../../containers/layout";
import SubPage from "../../containers/subpage";
import { graphql } from "gatsby"

import 'dayjs/locale/de';
import dayjs from "dayjs";
import RoadToSection from "../../containers/RoadToSection";

const RoadToPage = ({data}) => {
    let events = data.events.edges.map(({event}) => {
        let end = event.custom_fields.end;
        if(end) {
            end = dayjs(end * 1000).locale('de');
        }
        let start = event.custom_fields.start;
        if(start) {
            start = dayjs(start * 1000).locale('de');
        }
        return {
            title: event.title,
            start,
            end,
            subtitle: event.custom_fields.subtitle,
            link: event.custom_fields.link,
        }
    });

    return (
        <Layout pageTitle={"Programm"}>
            <SubPage
                back={'/programm/'}
                header={(
                    <>
                    <h2>Road-To-Slam2018</h2>
                    <p>Auf der «Road-to-Slam2018»-Tour quer durch die Deutschschweiz finden bis zu den Deutschsprachigen Meisterschaften im November auserlesene Poetry Slam-Veranstaltungen im Zeichen des «Slam2018» statt. Stimmt mit ein in das grosse Jubiläumsjahr des Schweizer Poetry Slams!</p>
                    </>
                )}
            >
                <div className={'container'}>
                    <RoadToSection events={events}/>
                </div>
            </SubPage>
        </Layout>
    );
};

export default RoadToPage;

export const query = graphql`
    query RoadToPageQuery {
        events: allWordpressWpEvents(
            filter: {content: {eq: ""}},
            sort: {fields: [custom_fields___wpcf_veranstaltungsdatum]}
        ){
            edges{
                event: node{
                    title
                    custom_fields{
                        start: wpcf_veranstaltungsdatum
                        end: wpcf_enddatum
                        subtitle: wpcf_untertitel
                        link: wpcf_link
                    }
                }
            }
        }
    }
`;