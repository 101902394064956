import React from 'react';
import injectSheet from 'react-jss';

import '../utils/intersection-observer';
import Observer from '@researchgate/react-intersection-observer';
import RoadToEntry from "../components/RoadToEntry";

const styles = theme => {
    let borderColor = theme.palette.secondary.dark;

    return {
        root: {
            fontSize: '1rem',
            [theme.breakpoints.down('xl')]: {
                fontSize: '.75rem',
            },
            [theme.breakpoints.down('xs')]: {
                fontSize: '.6rem',
            },
            [theme.breakpoints.down('xxs')]: {
                fontSize: '.5rem',
            },
            display: 'flex',
            flexDirection: 'column',
            marginTop: '2rem',
        },
        header: {
        },
        content: {
            marginTop: '1rem',
            display: 'flex',
            flexDirection: 'column',
            borderLeft: '2px solid '+borderColor,
            '& > *': {
                marginBottom: '2em',
                '&:last-child': {
                    marginBottom: 0,
                },
            }
        },
    };
};

let transformation = {
    normal: {
        transform: 'translateY(0)'
    },
    hidden: {
        transform: 'translateY(200px)'
    },
    transition: 'transform .5s ease-in',
};

class RoadToSection extends React.Component {
    state = {
        hidden: -1,
        loaded: false,
    };

    handleChange = (index, event) => {
        if(this.state.hidden < index && event.isIntersecting){
            this.setState({
                hidden: index
            });
        }
    };

    componentDidMount(){
        setTimeout(e => this.setState({loaded: true}), 1000);
    }

    render() {
        let {events, header, fold=false, className, classes} = this.props;
        let additionalClasses = '';
        if(fold){
            additionalClasses += ' folded';
        }
        if(className){
//        additionalClasses += ;
        }
        let hidden = this.state.hidden;
        let loaded = this.state.loaded;
        if(header){
            header = (
                <header className={classes.header}>
                    {header}
                </header>
            );
        }
        return (
            <section className={classes.root + ' ' + className}>
                {header}
                <div className={classes.content}>
                    {
                        events.map((event, index) => {
                            let styles = loaded && index > hidden? transformation.hidden: transformation.normal;
                            if(loaded){
                                styles = {
                                    ...styles,
                                    transition: transformation.transition,
                                };
                            }

                            return (
                                <Observer onChange={event => this.handleChange(index, event)}>
                                    <div>
                                        <div style={styles}>
                                            <RoadToEntry key={index} className={additionalClasses} {...event} />
                                        </div>
                                    </div>
                                </Observer>
                            );
                        })
                    }
                </div>
            </section>
        );
    }
}


export default injectSheet(styles)(RoadToSection);