import React from 'react';
import injectSheet from 'react-jss';

const styles = theme => {
    let borderColor = theme.palette.secondary.dark;
    let transition = '';//'color .25s ease-in, background .5s ease-in';

    return {
        root: {
            display: 'flex',
            position: 'relative',
            left: '-2px',
            '&.folded':{
                '& $date': {
                    width: '100%',
                },
                '& $content': {
                    transform: 'scaleX(0)',
//                    transform: 'scaleX(1)',
                    transformOrigin: 'left',
                    transition: 'transform .5s ease-in',
//                    transition: 'width .5s ease-in',
                    overflow: 'hidden',
                    width: '300%',
//                    width: 0,
                    padding: 0,
                    position: 'absolute',
                    left: '100%',
                    '& > *': {
//                        display: 'none'
                    }
                },
                '&:hover': {
                    '& $content': {
                        padding: '.25em .75em',
                        transform: 'scaleX(1)',
                        width: '300%',
                        '& > *': {
                            display: 'inherit'
                        }
                    }
                }
            },
            '&:hover':{
                '& $date':{
                    color: theme.palette.secondary.light,
                    borderColor: theme.palette.secondary.light,
                },
                '& $content':{
                    background: theme.palette.secondary.light,
                    color: theme.palette.secondary.dark,
                },
            },
        },
        date: {
            transition,

            position: 'relative',
            border: '2px solid '+borderColor,
//            borderLeftWidth: 0,

            fontSize: '2em',
            lineHeight: '1.1em',
            fontWeight: 600,
            width: '25%',
            height: '3em',
            background: theme.palette.secondary.dark,
            background: theme.palette.background,
            color: theme.palette.secondary.dark,

            padding: '.25em .75em',
            flex: '0 0 auto',
        },
        content: {
            transition,

            position: 'relative',
            flex: '1 1 auto',
            display: 'flex',
            justifyContent: 'space-between',
            flexWrap: 'wrap',
            padding: '.25em .75em',
            height: '6em',

            color: theme.palette.secondary.light,
            background: theme.palette.secondary.dark,
        },
        title: {
            fontSize: '2em',
            lineHeight: '1.1em',
            fontWeight: 600,
            width: '100%',
        },
        subtitle: {
            fontSize: '2em',
            lineHeight: '1.1em',
            fontWeight: 300,
            textTransform: 'uppercase',
        },
        link: {
            alignSelf: 'flex-end',
        },

    };
};

const RoadToEntry = ({title, subtitle, link, start, end, className, classes}) => {

    start = start.format('DD / MM');
    let date;
    if(end){
        end = end.format('DD / MM');
        date = (<time>{start} <br/>- {end}</time>);
    } else {
        date = (<time>{start}</time>);
    }

    let linkToDisplay = link;
    if(linkToDisplay){
        linkToDisplay = link[0];
        let indexOf = linkToDisplay.indexOf('//');
        if(indexOf>=0){
            linkToDisplay = linkToDisplay.substr(indexOf + 2);
        }
        indexOf = linkToDisplay.indexOf('/');
        if(indexOf>=0) {
            linkToDisplay = linkToDisplay.substr(0, indexOf);
        }
    }

    return (
        <a className={classes.root + className} href={link} target="_blank" rel="noopener">
            <span className={classes.date}>{date}</span>
            <span className={classes.content}>
                <span className={classes.title}>{title}</span>
                <span className={classes.subtitle}>{subtitle}</span>
                <span className={classes.link}>{linkToDisplay}</span>
            </span>
        </a>
    )
};

export default injectSheet(styles)(RoadToEntry);